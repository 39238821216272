<template>
  <div class="box">
    <div class="search">

      <el-form label-width="120px" label-height="60px">
        <el-row>
          <el-col :span="8">
            <el-form-item label="用户ID：" prop="memberId">
              <el-input v-model="form.memberId" placeholder="请输入" clearable></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="用户昵称：" prop="memberNikeName">
              <el-input v-model="form.memberNikeName" placeholder="请输入" clearable></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="用户手机号：" prop="phone">
              <el-input v-model="form.phone" placeholder="请输入" clearable></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="8">
            <!-- 是否为会员 -->
            <el-form-item label="VIP级别：" prop="vipLevel">
              <el-input v-model="form.vipLevel" placeholder="请输入" clearable></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="邀请人手机号：" prop="inviterPhone">
              <el-input v-model="form.inviterPhone" placeholder="请输入" clearable></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8" style="display: flex; justify-content: right;">
            <el-button @click="reset" icon="el-icon-refresh-left">重置</el-button>
            <el-button type="primary" @click="search" icon="el-icon-search">搜索</el-button>
          </el-col>
        </el-row>

      </el-form>
    </div>
    <div class="tab">
      <div class="add">
        <input id="file-input" type="file" @change="importClick" style="display: none;">
        <el-button type="primary" @click="downloadmodel">下载导入模板</el-button>
        <el-button type="primary" @click="document.getElementById('file-input').click();">历史数据导入</el-button>
        <el-button type="primary" @click="toExport">导出</el-button>
      </div>

      <el-table :data="tableData" style="width: 100%" show-summary height="calc(100vh - 380px)" border
        :header-cell-style="{
          'background-color': '#f2f2f2',
          'text-align': 'center',
          border: '1px solid #ddd',
        }">


        <el-table-column prop="memberId" label="用户ID" align="center" width=" 200">
        </el-table-column>
        <el-table-column prop="memberNikeName" label="用户昵称" align="center">
        </el-table-column>
        <el-table-column prop="vipLevel" label="VIP级别" align="center">
        </el-table-column>
        <el-table-column prop="phone" label="用户手机号" width="100" align="center">
        </el-table-column>
        <el-table-column prop="inviterId`" label="邀请人id" align="center">
        </el-table-column>
        <el-table-column prop="inviterName" label="邀请人名称" align="center">
        </el-table-column>
        <el-table-column prop="address" label="所在城市" width="100" align="center">
        </el-table-column>
        <el-table-column prop="balance" label="福卡余额" align="center">
        </el-table-column>
        <el-table-column prop="updateTime" show-overflow-tooltip label="上次交易时间" align="center">
        </el-table-column>


        <el-table-column prop=" " label="操作" width="250" fixed="right" align="center">
          <template slot-scope="scope">
            <el-button type="text" @click="deta(scope.row.id)">详情</el-button>
            <el-button type="text" @click="edit(scope.row.id)">编辑</el-button>
            <el-button type="text" @click="drawer = true" style="color: red;">删除</el-button>
          </template>
        </el-table-column>


      </el-table>
      <div class="pagination">
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
          :current-page.sync="queryInfo.currPage" :page-size="queryInfo.pageSize"
          layout="total, sizes, prev, pager, next, jumper" :total="total">
        </el-pagination>
      </div>
    </div>

    <!-- 编辑弹窗 -->
    <el-dialog title="会员编辑" :visible.sync="showDialog" width="600px" height="1000px">
      <div class="dia">
        <div class="diali">
          <div class="liname">用户ID：</div>
          <input type="text" :value="detail.memberId" placeholder="请输入" disabled>
        </div>
        <div class="diali">
          <div class="liname">用户昵称：</div>
          <input type="text" v-model="detail.memberNikeName" placeholder="请输入">
        </div>
        <div class="diali">
          <div class="liname">用户手机号：</div>
          <input type="text" v-model="detail.phone" placeholder="请输入">
        </div>
        <div class="diali">
          <div class="liname">vip等级：</div>
          <input type="text" v-model="detail.vipLevel" placeholder="请输入">
        </div>
        <div class="diali">
          <div class="liname">邀请人ID：</div>
          <input type="text" v-model="detail.inviterId" placeholder="请输入">
        </div>
        <div class="diali">
          <div class="liname">邀请人手机号：</div>
          <input type="text" v-model="detail.inviterPhone" placeholder="请输入">
        </div>
        <div class="diali">
          <div class="liname">所在城市：</div>
          <input type="text" v-model="detail.address" placeholder="请输入">
        </div>


        <div class="but">
          <el-button @click="showDialog = false">取消 </el-button>
          <el-button type="primary" @click="save">保存 </el-button>
        </div>

      </div>
    </el-dialog>
    <!-- 详情弹窗 -->
    <el-drawer title="会员详情" :visible.sync="drawer" size="1000px" :before-close="handleClose">
      <div class="drawer">
        <div class="drwtop">
          <div>用户ID： <span>{{ detail.memberId }}</span></div>
          <div>手机号： <span>{{ detail.phone }}</span></div>
          <div>交易总金额： <span>{{ detail.transactionAmount }}</span></div>
          <div>用户来源： <span>{{ detail.memberSource }}</span></div>
          <div>邀请人： <span>{{ detail.inviterName }}</span></div>
          <div>邀请人手机号： <span>{{ detail.inviterPhone }}</span></div>
          <div>可用积分： <span>{{ detail.point }}</span></div>
          <div>福卡余额： <span>{{ detail.balance }}</span></div>
          <div>所在城市： <span>{{ detail.address }}</span></div>
        </div>
        <div class="drwdetail">
          <div style="padding-left: 20px;">
            <el-tabs v-model="activeName" @tab-click="handleClick">
              <el-tab-pane label="积分明细" name="first">
                <div>
                  <el-table :data="tableData" style="width: 100%" height="calc(100vh - 380px)" border
                    :header-cell-style="{
                      'background-color': '#f2f2f2',
                      'text-align': 'center',
                      border: '1px solid #ddd',
                    }">
                    <el-table-column type="index" label="序号" align="center">
                    </el-table-column>
                    <el-table-column prop="name" label="关联订单编号" align="center">
                    </el-table-column>
                    <el-table-column prop="name" label="交易时间" align="center">
                    </el-table-column>
                    <el-table-column prop="name" label="交易积分" align="center">
                    </el-table-column>
                    <el-table-column prop="name" label="交易类型" align="center">
                    </el-table-column>
                  </el-table>
                </div>
              </el-tab-pane>
              <el-tab-pane label="微信充值明细" name="second">
                <div>
                  <el-table :data="tableData" style="width: 100%" height="calc(100vh - 380px)" border
                    :header-cell-style="{
                      'background-color': '#f2f2f2',
                      'text-align': 'center',
                      border: '1px solid #ddd',
                    }">
                    <el-table-column type="index" label="序号" align="center">
                    </el-table-column>
                    <el-table-column prop="name" label="充值金额" align="center">
                    </el-table-column>
                    <el-table-column prop="name" label="到账金额" align="center">
                    </el-table-column>
                    <el-table-column prop="name" label="充值类型" align="center">
                    </el-table-column>
                    <el-table-column prop="name" label="提交人" align="center">
                    </el-table-column>
                  </el-table>
                </div>
              </el-tab-pane>
              <el-tab-pane label="福卡兑换明细" name="third">
                <div>
                  <el-table :data="tableData" style="width: 100%" height="calc(100vh - 380px)" border
                    :header-cell-style="{
                      'background-color': '#f2f2f2',
                      'text-align': 'center',
                      border: '1px solid #ddd',
                    }">
                    <el-table-column type="index" label="序号" align="center">
                    </el-table-column>
                    <el-table-column prop="name" label="充值金额" align="center">
                    </el-table-column>
                    <el-table-column prop="name" label="充值卡号" align="center">
                    </el-table-column>
                    <el-table-column prop="name" label="充值类型" align="center">
                    </el-table-column>
                    <el-table-column prop="name" label="充值时间" align="center">
                    </el-table-column>
                  </el-table>
                </div>
              </el-tab-pane>
              <el-tab-pane label="邀请人明细" name="fourth">
                <div>
                  <el-table :data="tableData" style="width: 100%" height="calc(100vh - 380px)" border
                    :header-cell-style="{
                      'background-color': '#f2f2f2',
                      'text-align': 'center',
                      border: '1px solid #ddd',
                    }">
                    <el-table-column prop="name" label="邀请人姓名" align="center">
                    </el-table-column>
                    <el-table-column prop="name" label="邀请人手机号" align="center">
                    </el-table-column>
                    <el-table-column prop="name" label="获得积分" align="center">
                    </el-table-column>
                    <el-table-column prop="name" label="邀请时间" align="center">
                    </el-table-column>

                  </el-table>
                </div>
              </el-tab-pane>
            </el-tabs>
          </div>
        </div>
      </div>
    </el-drawer>
  </div>

</template>

<script>
export default {
  data() {
    return {
      drawer: false,
      activeName: 'first',
      total: 0,
      queryInfo: {
        pageSize: 10,
        currPage: 1,
        condition: {}
      },
      form: {},
      showDialog: false,
      tableData: [],
      detail: {}
    };
  },
  created() {
    this.getList()
  },
  mounted() {

  },
  methods: {
    //获取数据
    getList() {
      var that = this;
      that.$http.post("/member/list", that.queryInfo).then(function (response) {
        console.log(response.data.data);
        if (response.data.code == 200) {
          that.total = response.data.data.count;
          that.queryInfo.currPage = response.data.data.currPage
          that.tableData = response.data.data.data;
        }
      });
    },
    // 下载模版
    async downloadmodel(){
      const that = this
      return
      const res = await this.$http({
        method: 'post',
        url: '/business/generatePaymentCodeBatch',
        responseType: "blob"
      });
      if (res.status == 200) {
        try {
          const blob = new Blob([res.data]); // 创建Blob对象
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.setAttribute("download", `会员详情模版.xls`); // 设置导出的文件名称
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          that.$message({type:'success',message: '下载成功'})
        } catch (error) {
          this.$message.error(error);
        }
      }
    },
    // 导入
    async importClick(event){
      console.log('导入',event)
      const that = this
      const file = event.target.files[0];
      // 使用 FormData 包装文件
      const formData = new FormData();
      formData.append('file', file);
      return
      const res = await this.$http.post("importExcel/importBusiness", formData, {
        headers: {
            'Content-Type': 'multipart/form-data', // 设置正确的Content-Type
        },
      });
      if(res.data.code === 200){
          this.getList()
          that.$notify.success({
            title: "提示",
            message: "导入成功",
            showClose: true,
          });
      }else{
          this.$notify.info({
            title: "提示",
            message: res.data.message,
            showClose: true,
          });
      }
    },
    // 导出
    async toExport(){
      const that = this
      const _data = {
        ...that.queryInfo.condition
      }
      return
      const res = await this.$http({
        method: 'post',
        url: '/business/generatePaymentCodeBatch',
        responseType: "blob",
        data: _data
      });
      if (res.status == 200) {
        try {
          const blob = new Blob([res.data]); // 创建Blob对象
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.setAttribute("download", `会员详情.xls`); // 设置导出的文件名称
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          that.$message({type:'success',message: '导出成功'})
        } catch (error) {
          this.$message.error(error);
        }
      }
    },
    // 关闭抽屉
    handleClose(done){
      done()
    },
    // 修改页数大小
    handleSizeChange(val) {
      this.queryInfo.pageSize = val;
      this.getList()
    },

    // 获取当前页面
    handleCurrentChange(val) {
      this.queryInfo.currPage = val;
      this.getList()
    },
    search() {
      this.queryInfo.condition = this.form
      this.queryInfo.currPage = 1
      this.getList()
    },
    reset() {
      this.form = {}
      this.queryInfo.condition = {}
      this.queryInfo.currPage = 1
      this.getList()
    },
    deta(id) {
      this.drawer = true
      var that = this;
      that.$http.post("/member/queryOne", {
        id: id
      }).then(function (response) {
        console.log(response.data.data);
        if (response.data.code == 200) {

          that.detail = response.data.data

        }
      });
    },
    // 切换tab
    handleClick(e){
      console.log('tabclick',e)
    },
    edit(id) {
      this.showDialog = true
      var that = this;
      that.$http.post("/member/queryOne", {
        id: id
      }).then(function (response) {
        console.log(response.data.data);
        if (response.data.code == 200) {

          that.detail = response.data.data

        }
      });
    },
    save(){
      const that = this
      this.$confirm('是否确定修改会员详情?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          that.$http.post("/member/save", that.detail).then(function (response) {
            if (response.data.code == 200) {
              that.$message({
                type: 'success',
                message: '修改成功!'
              });
              that.getList()
            }
          });
        }).catch(() => {
          that.$message({
            type: 'info',
            message: '已取消'
          });          
        });
    },
  }
};
</script>

<style lang="less" scoped>
.box {
  .search {
    background-color: #fff;
    box-sizing: border-box;
    padding: 20px 20px 10px 10px;
  }

  .tab {
    background-color: #fff;
    margin-top: 20px;
    padding: 20px
  }

  .dia {
    .but {
      display: flex;
      justify-content: space-between;
      padding: 30px 200px;
    }

    .diali {
      display: flex;
      align-items: center;
      margin-bottom: 20px;

      .liname {
        width: 100px;
      }

      input {
        height: 30px;
      }
    }

  }

}

.drawer {

  .drwtop {
    display: flex;
    flex-wrap: wrap;
    padding: 0 20px;

    div {
      width: 300px;
      color: #000;
      height: 50px;

      span {
        color: #494848;
      }
    }
  }

  .drwdetail {
    margin-top: 50px;
  }
}
</style>